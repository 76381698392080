.app__navbar {
    position: fixed;
    top: 0;
    z-index: 9;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 15px;
    transition: border-radius 0.3s ease, box-shadow 0.3s ease;
}

.app__navbar.scrolled {
    border-radius: 0px 0px 20px 20px !important;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.app__navbar-logo .app__logo {
    width: 85px;
    margin-left: 10px;
}
.app__navbar-logo .app__logo1 {
    width: 130px;
    margin-top: 10px;
}

.app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;   
}

.app__navbar-links li {
    margin: 0 1rem;
    cursor: pointer;
    align-items: center;
    text-transform: capitalize;
    font-weight: 500;
    color: #Da281c;
    font-size: 20px;
    
}

.app__navbar-links li:hover {
    color: #Da281c !important;
}

.app__navbar-login {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.app__navbar-login a {
    margin: 0 1rem;
    text-decoration: none;
    transition: .5s ease;
}

.app__navbar-login a:hover {
    border-bottom: 1px solid var(--color-golden);
}

.app__navbar-login div {
    width: 1px;
    height: 30px;
    background: var(--color-grey);
}

.app__navbar-smallscreen {
    display: none;
}

.app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--color-black);
    transition: .5s ease;
    flex-direction: column;
    z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close {
    font-size: 27px;
    color: var(--color-golden);
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
}

.app__navbar-smallscreen_links {
    list-style: none;
}

.app__navbar-smallscreen_links li {
    margin: 2rem;
    cursor: pointer;
    color: var(--color-golden);
    font-size: 2rem;
    text-align: center;
    font-family: var(--font-base);
}

.app__navbar-smallscreen_links li:hover {
    color: var(--color-white);
}

@media screen and (min-width: 2000px) {
    .app__navbar-logo img {
        width: 210px;
    }
}

@media screen and (max-width: 1150px) {
    .app__navbar-links {
        display: none;
    }

    .app__navbar-smallscreen {
        display: flex;
    }
}

@media screen and (max-width: 650px) {

    .app__navbar-logo .app__logo {
        width: 65px !important;
        margin-left: 0 !important;
    }

    .app__navbar-logo .app__logo1 {
        width: 110px !important;
        margin-top: 10px !important;
    }
    .app__navbar {
        padding: 0.5rem 10px 0.5rem 2px;
    }

    .app__navbar-login {
        display: none;
    }

    .app__navbar-logo img {
        width: 199px;
        padding: 0 !important;
        justify-content: space-between;
    }
}