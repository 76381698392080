
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 1000;
}

.scroll-container {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: transparent;
}

.scroll-button {
  background-color: #da281c;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.3s;
}

.scroll-button:hover {
  background-color: #b5221a;
  transform: scale(1.1);
}

.scroll-text {
  font-size: 16px;
  color: #da281c;
}
