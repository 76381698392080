.app__header {
    background-color: #000;
}

.app__header-h1 {
    font-family: var(--font-base);
    color: #d9281c;
    font-weight: 700;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    line-height: 20px;
    font-size: 40px;
}
.main_logo {
    display: flex;
    padding: 10px 0px;
}

.main_logo .app__logo1{
    width: 200px;
    margin-top: 10px;
}


  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    max-width: 1300px;
    margin: 0 auto;
}
.about-us{
    font-size: 25px;
    letter-spacing: 0px;
}
.grid-item {
    position: relative;
    overflow: hidden;
}

.grid-item img {
    border-radius: 5px;
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s ease;
}

@media screen and (min-width: 2000px) {
    .app__header-h1 {
        font-size: 150px;
        line-height: 210px;
    }
}

@media screen and (max-width: 1150px) {
    .app__header-h1 {
        font-size:60px;
    }
}

@media screen and (max-width: 650px) {
    .app__header-h1 {
        font-size: 50px;
        line-height: 87px;
    }
}

@media screen and (max-width: 450px) {
    .app__header-h1 {
        font-size: 50px;
        line-height: 70px;
    }
}

@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .animate {
    opacity: 0;
    animation-fill-mode: forwards;
    animation-duration: 1s;
  }
  
  .animate.fade-in {
    animation-name: fadeIn;
  }