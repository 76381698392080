.menu-section {
  margin: 2rem;
  width: 95%;
  margin: 50px auto;
  text-align: center;
}

.mobile-view {
  display: none !important;
}

.menu-heading-title {
  font-size: 35px;
  margin-bottom: 20px;
  color: #Da281c;
}

.menu-heading-description {
  font-size: 18px;
  letter-spacing: 1.5px;
  line-height: 30px;
  color: #000;
  width: 80%;
  margin: 0px auto 30px auto;
}

.menu-list {
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
}

.menu-list li {
  margin: 2px 20px;
  font-weight: 700;
  font-size: 16px;
  list-style: none;
}

.categories {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.category-btn {
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  background-color: #da291c12;
  color: #Da281c;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 2px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.category-btn:hover {
  background-color: #Da281c;
  color: whitesmoke;
}

.category-btn.active {
  background-color: #Da281c;
  color: whitesmoke;
}

.subcategories {
  margin-bottom: 10px;
}

.subcategory-btn {
  margin: 10px 8px;
  padding: 5px 20px;
  border: none;
  background-color: #da291c12;
  color: #Da281c;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 2px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.subcategory-btn-mobile{
  margin: 10px 8px;
  padding: 5px 20px;
  border: none;
  background-color: #da291c12;
  color: #Da281c;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 2px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.subcategory-btn-menu {
  background-color: #ffe6e64b;
  border: none;
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 50px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* Optional: Add shadow for better appearance */
  font-size: 14px;
  /* Adjust font size */
}


.item-details {
  display: inline;
  text-align: left;
  width: 150%;
}

.item-name {
  flex: 1;
  color: #e60000;
  font-size: 14px;
  letter-spacing: 1;
  align-items: start;
}

.item-price {
  flex: 0 0 auto;
  margin-left: 10px;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #e60000;
}

.subcategory-btn:hover {
  background-color: #Da281c;
  color: whitesmoke;
}

.subcategory-btn.active {
  background-color: #Da281c;
  color: whitesmoke;
}

.menu-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.menu-item-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.menu-items {
  display: grid;
  justify-self: start;
  justify-content: start;
  grid-template-columns: repeat(3, 2fr);
  gap: 10px;
  margin-left: 30px;
  width: 100%;
  max-width: 700px;
}

.item-details {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.item-details span {
  font-weight: 500;
}

.menu-item h3 {
  margin: 15px 0;
  font-size: 16px;
  color: #Da281c;
  display: flex;
  align-items: center;
}

.menu-item h3 span {
  margin-left: 10px;
  justify-content: space-between;
  font-size: 17px;
  color: #000;
}

.menu-item p {
  margin: 5px 0;
  font-weight: bold;
  font-size: 16px;
  position: absolute;
  bottom: 0px;
  left: 50px;
  right: 50px;
}

.menu-item-container img {
  object-fit: cover;
  border-radius: 2%;
}


@media (max-width: 768px) {

  .app__wrapper_info{
    margin-top: 40px !important;
  } 

  .desktop-view {
    display: none !important;
  }

  .mobile-view {
    display: block !important;

    .menu-item {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
  }


  .subcategory-btn-menu {
    font-size: 10px;
    width: 280px;
    margin-left: auto;
    margin-right: auto;
    padding: 12px;
    height: auto;
    max-width: 100%;
  }

  .item-details {
    flex-direction: row;
    align-items: flex-start;
  }
 

  .menu-heading-title {
    font-size: 28px;
  }

  .menu-heading-description {
    font-size: 16px;
    line-height: 28px;
    width: 90%;
  }

  .menu-list {
    flex-direction: column;
    margin-bottom: 40px;
  }

  .menu-list li {
    margin: 10px 0;
  }

  .categories,
  .subcategories {
    margin-left: auto;
    display: block;
    margin-right: auto;
    flex-direction: column;
  }

  .category-btn,
  .subcategory-btn {
    margin: 10px 0;
    width: 80%;
  }

  .menu-items {
    grid-template-columns: 1fr 1fr;
    margin-left: 0;
    gap: 5px;
  }
}

@media (max-width: 425px) {

  .app__wrapper_info{
    margin-top: 40px !important;
  } 

  .desktop-view {
    display: none !important;
  }

  .mobile-view {
    display: block !important;

    .menu-item {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
  }


  .subcategory-btn-menu {
    font-size: 10px;
    width: 280px;
    margin-left: auto;
    margin-right: auto;
    padding: 12px;
    height: auto;
    max-width: 100%;
  }

  .item-details {
    flex-direction: row;
    align-items: flex-start;
  }
 

  .footer__copyright .text {
    font-weight: 600;
    font-size: 11px;
  }

  .menu-heading-title {
    font-size: 24px;
  }

  .menu-item-container {
    display: block;
  }

  .menu-section {
    margin: 20px auto;
  }

  .menu-heading-description {
    font-size: 14px;
    line-height: 24px;
    width: 100%;
  }

  .menu-list {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .menu-list li {
    margin: 5px 0;
  }

  .categories,
  .subcategories {
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .category-btn,
  .subcategory-btn {
    margin: 5px 0;
    width: 90%;
  }

  .menu-items {
    grid-template-columns: 1fr;
    margin-left: 0;
    gap: 5px;
  }

  .menu-item {
    padding: 0px 0px;
  }

  .menu-item h3 {
    font-size: 14px;
  }

  .menu-item h3 span {
    font-size: 15px;
  }

  .menu-item p {
    font-size: 14px;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 10px 0;
  }
}


@media (max-width: 700px) {

  .app__wrapper_info{
    margin-top: 40px !important;
  } 


  .desktop-view {
    display: none !important;
  }

  .mobile-view {
    display: block !important;

    .menu-item {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
  }


  .subcategory-btn-menu {
    font-size: 10px;
    width: 280px;

    margin-left: auto;
    margin-right: auto;
    padding: 12px;
    height: auto;
    max-width: 100%;
  }

  .item-details {
    flex-direction: row;
    align-items: flex-start;
  }
 

  .footer__copyright .text {
    font-weight: 600;
    font-size: 11px;
  }

  .menu-heading-title {
    font-size: 24px;
  }

  .menu-item-container {
    display: block;
  }

  .menu-section {
    margin: 20px auto;
  }

  .menu-heading-description {
    font-size: 14px;
    line-height: 24px;
    width: 100%;
  }

  .menu-list {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .menu-list li {
    margin: 5px 0;
  }

  .categories,
  .subcategories {
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .category-btn,
  .subcategory-btn {
    margin: 5px 0;
    width: 90%;
  }

  .menu-items {
    grid-template-columns: 1fr;
    margin-left: 0;
    gap: 5px;
  }

  .menu-item {
    padding: 0px 0px;
  }

  .menu-item h3 {
    font-size: 14px;
  }

  .menu-item h3 span {
    font-size: 15px;
  }

  .menu-item p {
    font-size: 14px;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 10px 0;
  }
}



@media (max-width: 320px) {

  .app__wrapper_info{
    margin-top: 40px !important;
  } 


  .desktop-view {
    display: none !important;
  }

  .mobile-view {
    display: block !important;

    .menu-item {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
  }


  .subcategory-btn-menu {
    font-size: 10px;
    width: 280px;
    padding: 12px;

    margin-left: auto;
    margin-right: auto;
    height: auto;
    max-width: 100%;
  }

  .item-details {
    flex-direction: row;
    align-items: flex-start;
  }
 

  .footer__copyright .text {
    font-weight: 600;
    font-size: 11px;
  }

  .menu-heading-title {
    font-size: 24px;
  }

  .menu-item-container {
    display: block;
  }

  .menu-section {
    margin: 20px auto;
  }

  .menu-heading-description {
    font-size: 14px;
    line-height: 24px;
    width: 100%;
  }

  .menu-list {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .menu-list li {
    margin: 5px 0;
  }

  .categories,
  .subcategories {
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .category-btn,
  .subcategory-btn {
    margin: 5px 0;
    width: 90%;
  }

  .menu-items {
    grid-template-columns: 1fr;
    margin-left: 0;
    gap: 5px;
  }

  .menu-item {
    padding: 0px 0px;
  }

  .menu-item h3 {
    font-size: 14px;
  }

  .menu-item h3 span {
    font-size: 15px;
  }

  .menu-item p {
    font-size: 14px;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 10px 0;
  }
}


@media (max-width: 375px) {

  .app__wrapper_info{
    margin-top: 40px !important;
  } 

  .desktop-view {
    display: none !important;
  }
  .mobile-view {
    display: block !important;

    .menu-item {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
  }

  .subcategory-btn-menu {
    font-size: 10px;
    width: 280px;

    margin-left: auto;
    margin-right: auto;
    padding: 12px;
    height: auto;
    max-width: 100%;
  }

  .item-details {
    flex-direction: row;
    align-items: flex-start;
  }
 

  .footer__copyright .text {
    font-weight: 600;
    font-size: 11px;
  }

  .menu-heading-title {
    font-size: 24px;
  }

  .menu-item-container {
    display: block;
  }

  .menu-section {
    margin: 20px auto;
  }

  .menu-heading-description {
    font-size: 14px;
    line-height: 24px;
    width: 100%;
  }

  .menu-list {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .menu-list li {
    margin: 5px 0;
  }

  .categories,
  .subcategories {
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .category-btn,
  .subcategory-btn {
    margin: 5px 0;
    width: 90%;
  }

  .menu-items {
    grid-template-columns: 1fr;
    margin-left: 0;
    gap: 5px;
  }

  .menu-item {
    padding: 0px 0px;
  }

  .menu-item h3 {
    font-size: 14px;
  }

  .menu-item h3 span {
    font-size: 15px;
  }

  .menu-item p {
    font-size: 14px;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 10px 0;
  }
}

@media (max-width: 768px) {

  .app__wrapper_info{
    margin-top: 40px !important;
  } 


  .desktop-view {
    display: none !important;
  }

  .mobile-view {
    display: block !important;

    .menu-item {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
  }


  .subcategory-btn-menu {
    font-size: 10px;
    width: 280px;
    padding: 12px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }

  .item-details {
    flex-direction: row;
    align-items: flex-start;
  }
 

  .menu-item-container {
    display: block;
  }

  .menu-items {
    margin-left: 0;
    gap: 5px;
  }

  .footer__copyright .text {
    font-weight: 600;
    font-size: 11px;
  }
}

.accordion-content {
  background-color: white !important;
}


.accordion-item.subcategory-btn {
  display: block;
  margin-left: auto;
  margin-right: auto;

}


.mobile-view {


.accordion-content {
  max-height: 300px; 
  overflow-y: auto;
}


.menu-items {
  max-height: 80vh;
  margin-left: auto;
  margin-right: auto;
  max-width: 82%;
  overflow-y: auto;
}

.subcategory-btn-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.item-details {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.item-name {
  flex: 1;
}

.item-price {
  margin-left: 10px;
}
}

