.app__footer {
    width: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background: #ffff;
    padding-top: 0;
}

.image{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.image .app__logo {
    width: 95px !important;
}
.image .app__logo1 {
    margin-top: 0px !important;
    width: 150px !important;
}

.app__footer-links {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  
    /* padding: 0 2rem; */
    font-weight: bold;
}

.app__footer-links_contact,
.app__footer-links_logo,
.app__footer-links_work {
    flex: 1;
    margin: 1rem;
    text-align: center;
}

.app__footer-links_logo img:first-child {
    width: 250px;
    margin-bottom: 0.75rem;
}


.app__footer-links_work p:nth-child(2n + 1) {
    margin-bottom: 1rem;
}

.app__footer-links_icons {
    margin-top: 0.2rem;
}

.app__footer-links_icons svg {
    color:black;
    margin: 0.5rem;
    font-size: 24px;
    cursor: pointer;
}

.app__footer-links_icons svg:hover {
    color: #Da281c;
}

.app__footer-headtext {
    font-family: var(--font-base);
    color: var(--color-white);
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 600;
    color: #Da281c;
    line-height: 41.6px;
    margin-bottom: 1rem;
}

.footer__copyright {

    width: 100%;
    text-align: center;
    background-color: #Da281c;
    color: whitesmoke;
}
.footer__copyright .text{
    margin: 0.8%;
    font-weight: 600;
}

@media screen and (min-width: 2000px) {
    .app__footer-headtext {
        font-size: 51px;
        line-height: 61.6px;
    }
}

@media screen and (max-width: 1150px) {
    .app__footer-links {
        align-items: center;
        flex-direction: column;
        padding: 0;
    }

    .app__footer-links_contact,
    .app__footer-links_logo,
    .app__footer-links_work {
        margin: 0.5rem 0;
        width: 100%;
    }
}

@media screen and (max-width: 650px) {
  .app__footer-links  .image{
        margin-left: auto;
        margin: auto;
        display: block;
        justify-content: flex-start;
        align-items: center;
    }
    
    .image .app__logo {
        width: 95px !important;
    }
    .image .app__logo1 {
        margin-top: 0px !important;
        width: 149px !important;
        margin-bottom: 10px;
    }
    .app__footer {
        padding: 0 0 2rem 0;
    }
}

@media screen and (max-width: 350px) {
    .app__footer-links  .image{
        margin-left: auto;
        margin: auto;
        display: block;
        justify-content: flex-start;
        align-items: center;
    }
    
    .image .app__logo {
        width: 95px !important;
    }
    .image .app__logo1 {
        margin-top: 0px !important;
        width: 149px !important;
        margin-bottom: 10px;
    }
    .app__footer-links  .image{
        margin-left: auto;
        margin: auto;
        display: block;
        justify-content: flex-start;
        align-items: center;
    }
    .app__footer-links_logo img:first-child {
        width: 80%;
    }
}
.app__footer-links_legal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #181818;
    margin-top: 20px;
  }
  
  .app__footer-links_legal a {
    color: #100f0f;
    text-decoration: none;
    margin-left: 20px;
  }
  
  .app__footer-links_legal a:hover {
    text-decoration: underline;
  }
  
  .app__footer-links_contact a:hover {
    color: #Da281c;
    font-weight: 600;
  }

  .app__footer-links_contact p span{
    font-weight: 500;
  }