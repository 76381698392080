.video-container {
    position: relative;
    width: 100%;
    background-color: black;
    padding-bottom: 50%;
    padding-top: 10px !important;
    height: 0;
    overflow: hidden;
  }
  
  .responsive-video {
    position: absolute;
    padding: 0;
    width: 100%;
    background-color: black;
    height: auto;
  }
  
  @media screen and (max-width: 768px) {
    .video-container {
      padding-top: 15% !important;
    }
  }

  @media screen and (max-width:425px) {
    .video-container {
      padding-top: 8% !important;
    }
  }

  @media screen and (max-width:375px) {
    .video-container {
      padding-top: 8% !important;
    }
  }

  @media screen and (max-width:320px) {
    .video-container {
      padding-top: 8% !important;
    }
    
  }