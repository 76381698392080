.franchise{
  height: 60vh;
}

.background-image {
  background-image: url('/src/assets/Carousel/4.png');
  background-size: cover;
  background-position: center;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}

.fcontent {
  background: rgba(0, 0, 0, 0.5);
  padding: 10px 100px;
  border-radius: 10px;
}

h1 {
  margin: 0;
  font-size: 3em;
}

p {
  margin: 0px 0 20px;
}

.fcontent .caption{
  font-size: 20px;
}

form {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: 268px;
  flex-direction: column;
  align-items: center;
}

input {
  margin: 10px 0;
  padding: 10px;
  width: 80%;
  border-radius: 5px;
  border: none;
}

button {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 20px;
  border: none;
  background-color: #Da281c;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}



.headtext__cormorant1 {
  font-family: var(--font-base);
  font-size: 35px;
  margin-bottom: 20px;
  color: whitesmoke;
  line-height: 83.2px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .fcontent {
    padding: 10px 20px;
  }

  .background-image {
    height:65vh !important; 
}

  h1 {
    font-size: 2em;
  }

  input {
    width: 61% !important;
    height: 20px !important;
  }

  .headtext__cormorant1 {
    font-size: 25px;
    line-height: 60px;
  }
}


@media (max-width: 480px) {
  .background-image {
    height:65vh !important; 
}

input {
  width: 61% !important;
  height: 20px !important;
}

.form{
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: 220px !important;
  flex-direction: column;
  align-items: center;
}

}

@media screen and (max-width: 320px) {
  .background-image {
    height:65vh !important; 
  }

  input {
    width: 61% !important;
    height: 20px !important;
  }


  .form{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    max-width: 220px !important;
    flex-direction: column;
    align-items: center;
  }
}